import { D as DATE_FORMAT } from "./audit.others.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "custom-url": _vm.prodApiUrl, "title": "Add Product Development" } }, [_c("text-input", { key: "projectNo", attrs: { "label": "Project Number", "placeholder": "Type Project Number", "form-item": "", "rules": "required", "max-length": 50, "span": 24 } }), _c("date-picker", { key: "sbtCompleted", staticClass: "w-100", attrs: { "format-date": _vm.DATE_FORMAT, "label": "SBT Completed", "span": 12, "rules": "required" } }), _c("date-picker", { key: "mbtCompleted", staticClass: "w-100", attrs: { "format-date": _vm.DATE_FORMAT, "label": "MBT Completed", "span": 12, "rules": "required" } }), _c("text-input", { key: "canInkjetCode", attrs: { "value": _vm.defaultSettings.canInkjetCode, "disabled": true, "label": "Can Inkjet Code", "placeholder": "Type Can Inkjet Code", "span": 12 } }), _c("date-picker", { key: "initialProduction", staticClass: "w-100", attrs: { "format-date": _vm.DATE_FORMAT, "label": "Initial Production", "span": 12 } }), _c("checkbox-input", { key: "tpCompleted", attrs: { "value": _vm.defaultSettings.tpCompleted, "span": 12, "form-item": false, "label": "TP Completed" } }), _c("checkbox-input", { key: "tpSent", attrs: { "value": _vm.defaultSettings.tpSent, "span": 12, "label": "TP Sent" } }), _c("checkbox-input", { key: "newProcessNow", attrs: { "value": _vm.defaultSettings.newProcessNow, "span": 12, "label": "Process Setup" } }), _c("checkbox-input", { key: "finalRecipe", attrs: { "value": _vm.defaultSettings.finalRecipe, "span": 12, "label": "Final Recipe" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateProductDevelopmentModal",
  inject: ["crud", "prodApiUrl"],
  data() {
    return {
      DATE_FORMAT,
      defaultSettings: {
        tpCompleted: false,
        tpSent: false,
        newProcessNow: false,
        finalRecipe: false,
        canInkjetCode: this.$route.query.inkjet
      }
    };
  },
  mounted() {
    this.storeValues();
  },
  methods: {
    storeValues() {
      const allSettings = Object.keys(this.defaultSettings);
      allSettings.forEach((item) => {
        this.storeValue({
          resourceKey: item,
          value: this.defaultSettings[item]
        });
      });
    },
    storeValue({ resourceKey, value }) {
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateProductDevelopmentModal = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-product-development-container" }, [_c("resource", { attrs: { "name": "sterilization.audit-groups.items.product-developments", "api-url": _vm.apiUrl, "create": _vm.CreateProductDevelopmentModal, "redirect-route": _vm.redirectRoute } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateProductDevelopment",
  data() {
    const { id, itemId } = this.$route.params;
    const redirectRoute = `/sterilization-audits/audit-groups/${id}/item/${itemId}`;
    return {
      apiUrl,
      redirectRoute,
      groupId: id,
      itemId,
      CreateProductDevelopmentModal
    };
  },
  provide() {
    return {
      prodApiUrl: `${this.apiUrl}/sterilization/audit-groups/${this.groupId}/items/${this.itemId}/product-developments`
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
